import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { trigger } from '../../packs/components/events'

const MOBILE_VIEWPORT_WIDTH = 991

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(window.innerWidth)
    }, 100)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

const sendAnalytics = (variant, quantity) => {
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ ecommerce: null }) // Clear any previous ecommerce objects
  dataLayer.push({
    ecommerce: {
      items: [{
        currency: variant.currency,
        item_id: variant.sku,
        item_name: variant.product_name,
        price: variant.price,
        quantity,
      }],
    },
    event: 'add_to_cart_new',
  })
}

const ProfileBuyItNow = ({ addToCartText, available, paymentData, variantId }) => {
  const windowWidth = useWindowSize()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleAddToCart = useCallback(() => {
    const quantityInput = document.querySelector(`input[data-id="line_item_${variantId}"]`)
    const quantity = quantityInput ? parseInt(quantityInput.value, 10) : 1

    setIsLoading(true)
    Spree.ensureCart(() => {
      SpreeAPI.Storefront.addToCart(
        variantId,
        quantity,
        {},
        (response) => {
          sendAnalytics(paymentData.variant, quantity)
          const fetchCartCountEvent = new CustomEvent('FETCH_CART_COUNT', {})
          document.dispatchEvent(fetchCartCountEvent)
          setIsLoading(false)

          if (windowWidth <= MOBILE_VIEWPORT_WIDTH) {
            window.location.href = Spree.routes.cart
          } else {
            trigger('openAddToCartSuccessModal', {
              addedCartItem: response,
              paymentData,
              quantity,
              variant: paymentData.variant,
            })
          }
          setError('')
        },
        (error) => {
          setError(error)
          setIsLoading(false)
        }
      )
    })
  }, [variantId, paymentData, windowWidth])

  return (
    <>
      <button
        aria-label={`Add ${paymentData.variant.product_name} to cart`}
        className="againAddToCart buttonComponent--primary buttonComponent--small"
        disabled={!available || isLoading || !!error}
        onClick={handleAddToCart}
      >
        {addToCartText}
      </button>
      {error && <div className="againAddToCartError">{error}</div>}
    </>
  )
}

ProfileBuyItNow.propTypes = {
  addToCartText: PropTypes.string,
  available: PropTypes.bool,
  paymentData: PropTypes.shape({
    old_price: PropTypes.string,
    on_sale: PropTypes.bool,
    price: PropTypes.string,
    product_id: PropTypes.number,
    variant: PropTypes.shape({
      brand: PropTypes.string,
      brand_name: PropTypes.string,
      category: PropTypes.string,
      currency: PropTypes.string,
      image_path: PropTypes.string,
      limit: PropTypes.number,
      name: PropTypes.string,
      old_price: PropTypes.string,
      on_sale: PropTypes.bool,
      price: PropTypes.string,
      product_name: PropTypes.string,
      sku: PropTypes.string,
      subsidy: PropTypes.string,
      value: PropTypes.number,
      volume_prices: PropTypes.array,
    }),
  }),
  variantId: PropTypes.number,
}

export default ProfileBuyItNow
