import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Pagination from './TCPagination'
import TCReview from './TCReview'
import ShopModal from './ShopModal'
import { renderStars } from './utils'

const TCProductReview = ({
                           avgRating,
                           isLoggedIn = false,
                           productReviewCount,
                           productReviews,
                           productReviewsWithImages,
                           productUrl,
                           reviewsCount,
                           signInUrl,
                           translations,
                         }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedReview, setSelectedReview] = useState(null)

  const setHeight = useCallback(() => {
    const content = document.getElementById('reviewsTop')?.nextElementSibling
    if (content) {
      content.style.maxHeight = 'initial'
    }
  }, [])

  const handleCarouselImageClick = useCallback((review) => {
    setSelectedReview(review)
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
    setSelectedReview(null)
  }, [])

  const modalBody = useMemo(() => {
    if (!selectedReview) return null

    return (
      <div className="modal-review-content">
        <div className="modal-review-image-wrapper">
          {selectedReview.image_large && (
            <img alt={selectedReview.title} className="modal-review-image" src={selectedReview.image_large} />
          )}
        </div>
        <div className="modal-review-info">
          <div className="modal-review-info-row">
            <div className="product-review-rating">{renderStars(selectedReview.rating)}</div>
            <div className="reviewComponent_nameDate">
              {selectedReview.show_identifier && <span>{selectedReview.name} • </span>}
              <span>{new Date(selectedReview.created_at).toLocaleDateString()}</span>
            </div>
          </div>
          {selectedReview.title && <h3 className="reviewComponent_title">{selectedReview.title}</h3>}
          <p className="reviewComponent_text">{selectedReview.review}</p>
        </div>
      </div>
    )
  }, [selectedReview])

  const carouselSettings = {
    infinite: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    }],
    slidesToScroll: 1,
    slidesToShow: 3,
  }

  return (
    <div className="reviewComponent" id="reviewsTop">
      <div className="review-header reviewComponent_headline">
        <div className="reviewComponent_headlineContainer container">
          <div className="reviewComponent_headlineContent">
            <h3>{translations.reviews.customer}</h3>
            <TCReview
              avg_rating={avgRating}
              product_url={productUrl}
              reviews_count={reviewsCount}
            />
            {productReviewCount === 0 && (
              <span>{translations.reviews.empty}</span>
            )}
          </div>
          {productReviewsWithImages.length > 3 ? (
            <Slider {...carouselSettings} className="reviewComponent_carousel">
              {productReviewsWithImages.map((review) => (
                <img
                  alt={review.title}
                  className="reviewComponent_carouselImg"
                  key={review.id}
                  onClick={() => handleCarouselImageClick(review)}
                  src={review.image_thumb}
                />
              ))}
            </Slider>
          ) : (
            <div className="reviewComponent_staticImages">
              {productReviewsWithImages.map((review) => (
                <img
                  alt={review.title}
                  className="reviewComponent_staticImage"
                  key={review.id}
                  onClick={() => handleCarouselImageClick(review)}
                  src={review.image_thumb}
                />
              ))}
            </div>
          )}
          <div className="reviewComponent_withButton">
            <h4><span>{translations.reviews.average}</span> {avgRating.slice(0, 3)}</h4>
            {isLoggedIn ? (
              <button
                className="buttonComponent--primary submit-product-review-btn"
                data-bs-target="#reviewModal"
                data-bs-toggle="modal"
              >
                {translations.reviews.button}
              </button>
            ) : (
              <h5 className="product-review-btn">
                <span>
                  <a href={signInUrl}>
                    {translations.reviews.login}
                  </a>
                  {translations.reviews.to_leave_a_review}
                </span>
              </h5>
            )}
          </div>
        </div>
      </div>
      <div className="reviewComponent_body" id="product-reviews">
        {productReviewCount !== 0 && (
          <div className="reviewComponent_bodyContainer container">
            <Pagination
              productReviewCount={productReviewCount}
              productReviews={productReviews}
              setHeight={setHeight}
              translations={translations.reviews}
            />
          </div>
        )}
      </div>
      <ShopModal
        handleClose={handleCloseModal}
        isModalOpen={isModalOpen}
        modalBody={modalBody}
      />
    </div>
  )
}

TCProductReview.propTypes = {
  avgRating: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  productReviewCount: PropTypes.number.isRequired,
  productReviews: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image_large: PropTypes.string,
    image_thumb: PropTypes.string,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
    show_identifier: PropTypes.bool.isRequired,
    title: PropTypes.string,
  })).isRequired,
  productReviewsWithImages: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image_large: PropTypes.string,
    image_thumb: PropTypes.string,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
    show_identifier: PropTypes.bool.isRequired,
    title: PropTypes.string,
  })).isRequired,
  productUrl: PropTypes.string.isRequired,
  reviewsCount: PropTypes.number.isRequired,
  signInUrl: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    reviews: PropTypes.shape({
      average: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      customer: PropTypes.string.isRequired,
      empty: PropTypes.string.isRequired,
      login: PropTypes.string.isRequired,
      readLess: PropTypes.string.isRequired,
      readMore: PropTypes.string.isRequired,
      review: PropTypes.string.isRequired,
      reviews: PropTypes.string.isRequired,
      to_leave_a_review: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default TCProductReview
